@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.modal-sub-container {
  padding: 16px 24px 24px;

  &.assessment-page {
    padding-bottom: 0;
  }
}

.modal-container {
  @media (min-width: 1080px) {
    width: 1000px;
  }
}

.modal-header {
  font-size: 1.5rem;
  line-height: 140%;
  color: $blue;
  &.center {
    text-align: center;
    flex-grow: 1;
    @media (min-width: 768px) {
      margin-left: -64px;
    }

    @media (max-width: $mobile) {
      flex-grow: 0.7;
    }
  }

  @media (max-width: $mobile) {
    font-size: 1.25rem;
  }
}

.modal-sub-header {
  margin-top: 24px;
  line-height: 140%;
}

.section-header {
  font-weight: 700;
}

.section-container {
  display: flex;
  border-radius: 5px;
  border: 1px solid $gray-background;
  margin-top: 24px;
  align-items: center;
  padding: 24px;
  cursor: pointer;
  &:hover {
    background-color: $light-crystal-blue;
  }
}

.section-title-container {
  margin-left: 12px;
}

.modal-link {
  margin-top: 21px;
  a {
    text-decoration: none;
    color: #1e749f;
    cursor: pointer;
  }
}

.back-button {
  color: $action-blue;
  display: flex;
  background: none;
  border: none;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.arrow-icon {
  transform: rotateY(180deg);
  margin-top: -1px;
}

.section-top {
  display: flex;
  align-items: center;
  padding: 16px 16px 0px 16px;
}

.input-wrapper {
  margin-top: 16px;
}

.below-input-text {
  font-style: italic;
  font-size: 0.875rem;
  color: $gray-text;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  align-items: center;
  @media (min-width: 768px) {
    margin-top: 48px;
  }
  box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 8px;
}

.textarea-wrapper {
  margin-top: 24px;
}

.success-count-wrapper {
  height: 50px;
}

.success-count-section {
  border-radius: 3px 0px 0px 3px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  margin-top: 24px;
  font-size: 1.125rem;
  overflow: hidden;
  &.pop {
    animation: popAnimation 1s ease;
  }
}

.CTA-icon {
  width: 20px;
  color: $action-blue;
}

.learn-more-link {
  font-size: 0.75rem;
}

.back-text {
  @media (max-width: $mobile) {
    display: none;
  }
}

@keyframes popAnimation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.9);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
