@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

@keyframes spin-counterclockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.upcomingMeetings {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  &.empty {
    display: grid;
    height: calc(100% - 32px);
    grid-template-rows: 1fr 1fr 1fr;
    background: $white;
  }

  .iconSpin {
    animation: spin-counterclockwise 2s linear infinite;
  }

  .calTitle {
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    height: min-content;
    gap: 24px;

    svg {
      stroke: none;
    }
  }

  .meetingContainer {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    gap: 16px;
  }

  .meetingHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .disconnectedLink {
    cursor: pointer;
    text-decoration: none;
  }

  .meetingHeaderRight {
    display: flex;
    align-items: center;
    gap: 16px;
    button {
      margin-bottom: 0;
    }
  }

  .disabledReportButton {
    background-color: $disabled-gray;
    color: $white;
  }

  .attendeeContainer {
    flex-direction: column;
    gap: 8px;
    display: none;
  }

  .attendeeBox {
    display: flex;
    gap: 8px;
    padding: 8px;
    &:hover {
      background: $gray-background-two;
    }
  }

  .attendeePhoto {
    width: 40%;
    min-width: 84px;
    div {
      font-size: 10px;
      padding: 2px 4px;
      line-height: 12px;
    }
  }

  .attendeeTitles {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .attendeeTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 16px);
  }

  .caretContainer {
    padding: 4px;
    cursor: pointer;
  }

  .caret {
    width: 10px;
    height: 10px;

    path {
      stroke: $gray-text;
    }

    &.left {
      transform: rotate(180deg);
    }
  }

  .calendarToggleContainer {
    margin-top: 8px;
    padding: 4px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: $bg-gray-2;
  }

  .titleDateContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    svg {
      @include svg-bold();
    }
  }

  :global(.show) {
    display: flex;
  }

  .chevronContainer {
    height: fit-content;
    &.rotate {
      transform: rotate(90deg);
    }
  }

  .titleDetails {
    display: flex;
    gap: 1.5em;
    no-wrap: true;
    @media (max-width: 786px) {
      flex-direction: column;
    }
  }

  .title {
    font-size: 0.875rem;
  }

  .refreshIcon {
    @include svg-bold();
    strokewidth: 1;
    stroke: $action-blue;
    height: 24px;
    cursor: pointer;
  }

  .topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .loadingArea {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .emptyState {
    display: flex;
    flex-direction: column;
    gap: 26px;
    align-items: center;
    justify-content: center;
    grid-row: 2;
  }

  .tooManyAttendeesCta {
    display: flex;
    column-gap: 8px;
    font-size: 0.875rem;
    svg {
      stroke: none;
    }
  }

  .emptyCalendar {
    height: 89px;
    width: 89px;
  }

  .connectSection {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .emptyCalMini {
    stroke: $action-blue;
    align-items: center;
    height: 18px;
    width: 18px;
  }

  .centerTitle {
    text-align: center;
    max-width: 375px;
  }
}
