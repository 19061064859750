@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: 10000;
  cursor: pointer;

  .iconWrapper {
    cursor: pointer;
    &:hover {
      opacity: 0.87;
    }
  }

  .icon {
    @include svg-bold();
    color: $action-blue;
    margin-left: 4px;
    height: 20px;
  }
  .drawerHeader {
    background: $white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    @media(max-width: 1200px) {
      margin-bottom: 30px;
    }

    .drawerHeaderBar {
      position: absolute;
      left: 50%;
      cursor: pointer;
      transform: translateX(-50%);
      width: 300px;
      height: 8.5px;
      border-radius: 10px;
      background-color: $bg-gray-3;
      @media(max-width: 1200px) {
        margin-top: 30px;
      }
    }

    .drawerHeaderRight {
      flex: 0 1 auto;
      margin-left: auto;
      align-items: flex-start;
      justify-content: flex-end;
      min-width: 400px;
      display: flex;
    }
  }

  .scrollToTop {
    position: fixed;
    bottom: 30px;
    right: 50px;
    cursor: pointer;
    background: $light-crystal-blue;
    z-index: 1000;
    border-radius: 50%;
    min-height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .scrollIcon {
    &:hover {
      opacity: 0.87;
    }
    rotate: -90deg;
    color: $action-blue;
    height: 2em;
    width: 2em;
  }

  .scrollPopUp {
    max-width: 8rem !important;
  }

  :global(.drawer-content) {
    background: $white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0 24px;
    cursor: default;
    position: absolute;
    transition: top 0.3s ease;
    bottom: 0;
  }

  :global(.open) {
    top: 0;
  }

  :global(.opening) {
    top: 100%;
  }

  :global(.closing) {
    top: 100%;
  }

  @media print {
    visibility: hidden;
  }
}


